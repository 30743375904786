@media screen and (min-width: 1900px) {
  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 0 93px;
    box-sizing: border-box;
    border-bottom: 1px solid #E5E9F2;
    height: 100px;
  }
  .header__logo {
    width: 142px;
  }
  .header__tabs{
      width: 48%;
      display: flex;
      justify-content: space-between;
  }
  .header__navtabs{
    display: flex;
    width: 63%;
    justify-content: space-between;

  }
  .header__tab {
    font-size: 22px;
    line-height: 20px;
    font-feature-settings: "salt" on, "liga" off;
  }
  .header__tab.activated {
    font-weight: 700;
  }
  .header__tab_language {
    color: #949494;
    display: flex;
    align-items: flex-end;
    cursor: pointer;
  }
  .active_language{
    color: #18191F;
  }
}
